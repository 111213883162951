.App {
  text-align: center;
  position: relative;
  padding-bottom: 50px;
  box-sizing: border-box;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  background-color: #E5FFD6;
  display: flex;
  flex-flow: column;
}

h1{
  margin-top: 0.5rem;
}

p {
  font-size: 1rem;
  text-align: left;
}

Button {
  margin-left: 1rem;
}

.card {
  margin: 1rem 1rem;
  border-color: #72CC3D;
  background-color: #FCFCFC;
}

.root {
  flex: 1;
}

footer {
  border-color: #72CC3D;
  background-color: #054D20;
  color: #FCFCFC;
  padding-top: 1rem;
  bottom: 0;
  width: 100%;
}

.cover {
  margin-bottom: 1rem;
  border-color: #000000;
}

.homepage {
  color: #FCFCFC;
  text-decoration: underline;
}

.share_icon {
  float: left;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.banner {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1024px){
  p{
    font-size: 0.85rem;
  }
}
